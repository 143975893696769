import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import mainImg from "../../images/fleet/disabled-access-coach.jpg"
import interiorImg from "../../images/fleet/disabled-access-coach-interior.jpg"
import NewsStrip from "../../components/news-strip"
import arrowSvg from "../../images/arrow-circle-right-solid.svg"

class NewsPage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Disabled access coach hire" description="View our disabled access coach. Coach hire for up to 48 passengers with Tetley's Coaches - Leeds' oldest bus and coach company established 1949." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Disabled access coach hire</h1>
            </div>

            <div className="news-article-body">
              <p>If your group has members with any special accessibility requirements our disabled access coaches may be the choice for you. Sizes are 48 seats. Two seats are removable to make way for one wheelchair to board the coach and can be locked securely on the front row</p>
              <p className="news-article-img">
                <img src={mainImg} alt="Disabled access coach hire" />
                <img src={interiorImg} alt="Disabled access coach hire interior" />
              </p>
              <ul>
                <li>48 seats or 46 plus one wheelchair</li>
                <li>Automated wheelchair lift </li>
                <li>3-point seat belts</li>
                <li>Climate Control</li>
                <li>Reclining seats</li>
                <li>CCTV – internal and external</li>
                <li>GPS tracking </li>
                <li>Large luggage compartment</li>
                <li>PA and Radio system</li>
                <li>WiFi (Subject to availability)</li>
              </ul>
              <p className="text-center">
                <a className='btn btn-grow btn-quote' href="https://portal.tetleyscoaches.co.uk/Quotation/NewQuotation" target="_blank" rel="noopener noreferrer">
                  <span className='btn-quote__text'>Get quote</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </a>
              </p>
              <p className="fleet-smallprint">Please note due to the different vehicle makes and models we have among our fleet of 60 the vehicle sent may differ from the one pictured.</p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default NewsPage
